<template>
  <v-row>
    <v-col v-if="!onlyInfo" cols="12">
      <v-switch
        v-model="useCustomCurrencies"
        :label="$t('company.use.custom.currencies')"
      />
    </v-col>

    <v-col cols="12">
      <v-data-table
        :key="sDataTableKey"
        :headers="headers"
        :items="items"
        :loading="loading"
        class="dt-listing-simple"
        hide-default-footer
      >
        <template #[`item.name`]="{ item }">
          <currency-preview v-if="item.rate_value === 1" :item="item" />
          <currency-rate-history-dialog v-else :currency="item.code" />
        </template>

        <template v-slot:[`item.active`]="{ item }">
          <active-icon :active="item.active" />
        </template>

        <template v-slot:[`item.is_default`]="{ item }">
          <active-icon :active="item.is_default" />
        </template>

        <template #[`item.company_rate`]="{ item }">
          <company-currency-rate-field
            :auto-save="autoSave"
            :company-id="obCompany.id"
            :currencies="obCurrencies"
            :disabled="item.is_default"
            :readonly="onlyInfo"
            :value="item"
            @update:item="onUpdateRate"
          />
        </template>

        <template #[`item.round`]="{ item }">
          <currency-rate-round-select
            :company-id="obCompany.id"
            :currency="item"
          />
        </template>

        <template #[`item.code_account`]="{ item }">
          <company-currency-account-code
            :company-id="obCompany.id"
            :value="item"
          />
        </template>

        <template #[`item.coderya`]="{ item }">
          <company-currency-rya-code
            v-if="!!obCompany.id"
            :company-id="obCompany.id"
            :value="item"
          />
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { AppModule } from "@/store/app";
import type {
  CompanyData,
  CompanySettingsData,
  CurrencyRate,
} from "@planetadeleste/vue-mc-gw";
import {
  Company,
  CompanySettings,
  CurrencyRates,
} from "@planetadeleste/vue-mc-gw";
import type {
  CurrencyCollection,
  CurrencyData,
} from "@planetadeleste/vue-mc-shopaholic";
import { Component, Mixins, Prop, VModel } from "vue-property-decorator";
import type { DataTableHeader } from "@/mixins/DataTableMixin";
import DataTableMixin from "@/mixins/DataTableMixin";
import { EventBus } from "@/services/event-bus";
import CurrencyPreview from "@/modules/currencies/components/CurrencyPreview.vue";
import CompanyCurrencyRateField from "@/modules/companies/components/CompanyCurrencyRateField.vue";
import CurrencyRateRoundSelect from "@/modules/currencyrates/components/CurrencyRateRoundSelect.vue";
import { filter, find } from "lodash";
import CurrencyRateHistoryDialog from "@/modules/currencyrates/components/CurrencyRateHistoryDialog.vue";
import CompanyCurrencyAccountCode from "@/modules/companies/components/CompanyCurrencyAccountCode.vue";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import CompanyCurrencyRyaCode from "@/modules/companies/components/CompanyCurrencyRyaCode.vue";
import { ConfigModule } from "@/store/config";
import { number } from "mathjs";
import { canModuleAccess } from "@/services/moduleAccess";

@Component({
  components: {
    CompanyCurrencyRyaCode,
    ActiveIcon,
    CompanyCurrencyAccountCode,
    CurrencyRateHistoryDialog,
    CurrencyPreview,
    CompanyCurrencyRateField,
    CurrencyRateRoundSelect,
  },
})
export default class CompanyCurrenciesRates extends Mixins(DataTableMixin) {
  @VModel({ type: Object, default: () => new Company() })
  obCompany!: Company;
  @Prop({ type: Boolean, default: true }) readonly autoSave!: boolean;
  @Prop(Boolean) readonly onlyInfo!: boolean;

  obCurrencies: CurrencyRates = new CurrencyRates();
  loading = false;
  headers: DataTableHeader[] = [
    { text: "name", value: "name", sortable: false },
    { text: "active", value: "active", sortable: false },
    {
      text: "isdefault",
      value: "is_default",
      class: "mw-150",
      sortable: false,
    },
    { text: "currency.symbol", value: "symbol", sortable: false },
    { text: "currency.iso.code", value: "code", sortable: false },
    {
      text: "currency.rya.code",
      value: "coderya",
      sortable: false,
      width: 200,
    },
    { text: "bcu.rate", value: "rate", sortable: false },
    { text: "company.rate", value: "company_rate", sortable: false },
    { text: "account.code", value: "code_account", sortable: false },
    { text: "currency.round", value: "round", sortable: false },
  ];
  arCurrencyIdList: number[] = [];
  obSettings: CompanySettings = new CompanySettings();

  get currencies(): CurrencyCollection {
    return AppModule.currencies;
  }

  get arInvoiceTypeList() {
    return ConfigModule.companyInvoiceTypes;
  }

  get companyCurrencies() {
    return ConfigModule.companyCurrencies;
  }

  get items(): CurrencyData[] {
    return this.arCurrencyIdList.length && this.currencies.length
      ? filter(
          this.currencies.getModelList() as CurrencyData[],
          (obItem: CurrencyData) => this.arCurrencyIdList.includes(obItem.id)
        )
      : [];
  }

  get useCustomCurrencies(): boolean {
    return this.obSettings
      ? this.obSettings.get("use_custom_currencies", false)
      : false;
  }

  set useCustomCurrencies(bValue: boolean) {
    if (this.obSettings) {
      this.obSettings.set("use_custom_currencies", bValue);
      this.onChangeSettings();
    }
  }

  get disableCustomCurrenciesSwitch(): boolean {
    return this.useCustomCurrencies && this.obCurrencies.length > 0;
  }

  get hasReceiptInvoiceType(): boolean {
    return (
      !!this.obCompany &&
      !!this.obCompany.id &&
      filter(this.arInvoiceTypeList, (obModel) => number(obModel.code) === 701)
        .length > 0
    );
  }

  mounted() {
    if (this.onlyInfo) {
      this.delDTHeader([
        "round",
        "code_account",
        "coderya",
        "active",
        "is_default",
      ]);
    } else {
      this.delDTHeader(["rate", "company_rate"]);
    }

    if (!this.hasReceiptInvoiceType) {
      this.delDTHeader(["coderya"]);
    }

    if (!canModuleAccess("accounting")) {
      this.delDTHeader(["code_account"]);
    }

    this.mapDTHeaders();
    this.load();
  }

  async onChangeSettings() {
    if (!this.obSettings) {
      return;
    }

    const obResponse = await this.obCompany.updateSettings(
      this.obSettings.attributes as Partial<CompanySettingsData>
    );
    this.obCompany.set("settings", obResponse.getData().data);
    AppModule.setCompany(this.obCompany.attributes as CompanyData);
    this.updateTableColumns();
  }

  onUpdateRate(obRate: CurrencyRate) {
    this.$emit("update:rate", obRate);
  }

  /**
   * Add/Remove company rates column, based on useCustomCurrencies property value
   */
  updateTableColumns() {
    if (!this.useCustomCurrencies) {
      this.delDTHeader("company_rate");
    } else if (
      !this.onlyInfo &&
      !find(this.headers, { value: "company_rate" })
    ) {
      this.addDTHeader(
        {
          text: "company.rate",
          value: "company_rate",
          sortable: false,
        },
        true
      );
    }
  }

  async load() {
    if (!this.obCompany.id) {
      return;
    }

    // Set company settings
    if (this.obCompany.get("settings")) {
      this.obSettings = new CompanySettings(
        this.obCompany.get("settings") as CompanySettingsData
      );
    }

    this.loading = true;

    const obCurrencyRateCollection = new CurrencyRates();
    // @ts-ignore
    await obCurrencyRateCollection
      .filterBy({ company: this.obCompany.id, rated: 1 })
      .fetch();
    this.obCurrencies = obCurrencyRateCollection;
    EventBus.emit("get.company.currencies", this.obCurrencies);

    // Load company currencies
    if (this.obCompany.id === AppModule.companyId) {
      this.arCurrencyIdList = ConfigModule.companyCurrencyIdList;
    } else {
      const obCompanyCurrencyRateCollection = new CurrencyRates();
      await obCompanyCurrencyRateCollection
        .filterBy({ companyGrouped: this.obCompany.id })
        .fetch();
      this.arCurrencyIdList = obCompanyCurrencyRateCollection.length
        ? obCompanyCurrencyRateCollection.map("currency_id")
        : [];
    }

    this.updateTableColumns();
    this.loading = false;
  }
}
</script>
